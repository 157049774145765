import { Button, Card, Modal } from 'react-bootstrap';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { List, Progress } from 'antd';
import { useEffect, useState } from 'react';
import { Badge } from 'react-bootstrap';
import { asyncWrap } from '../../utils/utils';
import axios from 'axios';
import CloseModalTab from '../../component/Nav/CloseModalTab';
import GiftDetailModal from './GiftDetailModal';
import Swal from 'sweetalert2';
import { useAuth } from '../../context/auth-context';

const CampaignRedeem = ({
  show,
  setShow
}) => {
  const [campaignData, setCampaignData] = useState()
  const [loading, setLoading] = useState(false)
  const getCampaignData = async () => {
    const [error, result] = await asyncWrap(axios.get("/campaign"));
    if (error?.response?.status === 401) {
      Swal.fire("Please Logout and login again")
      return;
    }
    if (!result) {
      console.log(error.response);
      return;
    } else {

      const CampaignData = result.data?.data.filter((item) => (item.BalancePoints !== 0 && item.CampaignName !== "Diwali Scheme Points Earned" && item.CampaignName !== "Year End 23-24 Scheme Points Earned"))

      const campaign_data = CampaignData.filter((item) => !item.CampaignName.toLowerCase().includes("other"))

      setCampaignData(campaign_data)
    }
  };

  const redeemCampaign = async (point, campaignId, campaignName, commit) => {
    const [error, result] = await asyncWrap(
      axios.post("/addRedeem", {
        points: point,
        type: "Bank",
        campaignId: campaignId,
        campaignName: campaignName,
        commit: commit
      })
    );
    if (!error) {
      setLoading(false)
      Swal.fire(result.data.message).then(() => {
        console.log('Alert closed');
        getCampaignData()
      });
      return;
    }
    Swal.fire(error.data.message).then(() => {
      setLoading(false)
      window.location.reload()
    });
  };

  useEffect(() => {
    getCampaignData()
  },[])

  return (
    <>
      <Modal fullscreen={true} show={show}>
        {loading ? (
          <div className="quiz-loader-main">
            <div id="quiz-loader"></div>
          </div>
        ) : (<Modal.Body className='w-100 p-2 mt-3'>
          <CloseModalTab onclick={() => setShow(false)} />

          <div>

          <List
            style={{ marginTop: "10px" }}
            dataSource={campaignData}
            renderItem={(item, key) => (
              <List.Item
                className="my-3 shadow rounded-3 p-3"
                onClick={() => {
                  setLoading(true)
                  redeemCampaign(item.BalancePoints, item.CampaignId, item.CampaignName, item.CampaignName + item.BalancePoints)
                }}
                key={key}
              >
                <div className="w-75">
                  <div
                    style={{ color: "#434343", fontSize: "16px" }}
                    className="fw-bold"
                  >
                    {item.CampaignName}
                  </div>
                </div>
                <div
                  style={{ color: "#3A69DD", fontSize: "20px", width:"100%" }}
                  className="fw-bold d-flex flex-row justify-content-between align-items-end"
                >
                  <div>🏆 {item?.BalancePoints}</div>
                  <Button style={{ width:"auto" }}>Redeem</Button>
                </div>
                <div>
                </div>
              </List.Item>
            )}
          />

            {/* <Row>
              <Col
                xs={20}
                style={{
                  marginTop: '20px',
                }}
              >
                <Card
                  style={{
                    backgroundColor: '#fff',
                    height: '100%',
                    marginBottom: '10px',
                    boxShadow: "0px 4px 20px rgba(254, 169, 101, 0.5)",
                    borderRadius: '0px',
                  }}
                  // onClick={() => {
                  //   setVoucherDetail(item);
                  //   setShowVoucherDetailModel(true);
                  // }}
                >
                  {/* <div
                          style={{
                            borderRadius: "15px",
                            display: "flex",
                            marginTop: "10px",
                            justifyContent: "space-between",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "20px",
                              color: "#fff",
                              marginLeft: "20px",
                              marginTop: "15px",
                            }}
                          >
                            {item?.Brand}
                          </p>
                          <Card.Img
                            src={item?.ImageUrl}
                            style={{
                              width: "64px",
                              height: "64px",
                              backgroundColor: "#FFCA9F",
                              marginRight: "20px",
                            }}
                          />
                        </div> 
                  <p
                    style={{
                      color: '#000',
                      marginLeft: '20px',
                      marginTop: '10px',
                    }}
                  >{`Earn 10 points to Redeem this voucher`}</p>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Card.Text
                      style={{
                        marginLeft: '20px',
                        marginTop: '5px',
                        color: '#000',
                        fontWeight: '500',
                        fontSize: '16px',
                      }}
                    >
                      ProductServiceName
                    </Card.Text>

                    <Button
                      variant='success'
                      style={{
                        marginRight: '20px',
                        borderRadius: '10px',
                      }}
                    >
                      Redeem
                    </Button>
                  </div>
                </Card>
              </Col>
            </Row> */}
          </div>
        </Modal.Body>)}
      </Modal>
    </>
  );
};

export default CampaignRedeem;
