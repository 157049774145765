import { useState, useEffect, useContext } from "react";
import EditRedeemDetails from "../component/RedeemModal/EditRedeemDetails";
import { asyncWrap } from "../utils/utils";
import axios from "axios";
import Swal from "sweetalert2";
import { Skeleton, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { eventLog } from "../utils/eventLog";
import { WebAppConfig } from "../context/WebAppConfig";
import { webAppConfigIsVisible } from "../utils/isVisible";
import PaytmIcon from "../icons/paytmIcom";
import BhimUpiIcon from "../icons/bhimupiIcon";
import BankIcon from "../icons/bankIcon";
import PaytmModal from "../component/Redeem/PaytmModal";
import BankModal from "../component/Redeem/BankModal";
import UpiModal from "../component/Redeem/UpiModal";
import TransferPointsModal from "../component/Redeem/TransferPointsModal";
import VoucherModal from "../component/Redeem/VoucherModal";
import SuccessModal from "../component/Redeem/SuccessModal";
import FailedModal from "../component/Redeem/FailedModal";
import { useHistory } from "react-router";
import MissingKyc from "../component/Redeem/MissingKyc";
import { useAuth } from "../context/auth-context";
import GiftModel from "../component/Redeem/GiftModel";
import CampaignRedeem from "../component/Redeem/CampaignRedeem";

const RedeemPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const webAppConfigData = useContext(WebAppConfig);
  const [redeemType, setRedeemType] = useState();
  const [showEditDetails, setShowEditDetails] = useState(false);
  const [point, setPoint] = useState();
  const [loading, setLoading] = useState(false);
  const [paytmNumber, setPaytmNumber] = useState();
  const [upiId, setUpiId] = useState();
  const [account, setAccount] = useState();
  const [ifsc, setIfsc] = useState();
  const [bankName, setBankName] = useState();
  const [name, setName] = useState();
  const [userApproved, setUserApproved] = useState();
  const [totalRedeemPoints, setTotalRedeemPoint] = useState();
  const [mobile, setMobile] = useState();
  const [showPaytmModal, setShowPaytmModal] = useState(false);
  const [showBankModal, setShowBankModal] = useState(false);
  const [showUpiModal, setShowUpiModal] = useState(false);
  const [transferPointModal, setTransferPointsModal] = useState(false);
  const [voucherModal, setVoucherModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFailedModal, setShowFailedModal] = useState(false);
  const [ratioOfPoints, setRatioOfPoints] = useState();
  const [showMissingKyc, setShowMissingKyc] = useState(false);
  const [showGiftModel, setShowGiftModel] = useState(false);
  const { user } = useAuth();
  //eslint-disable-next-line
  const [totalNonRedeemPoints, setTotalNonRedeemPoint] = useState();
  const [vouchers, setVouchers] = useState();
  const [allGiftDetail, setAllGiftDetail] = useState();
  const [instagramID, setInstagramID] = useState("0");
  const [panVerify, setPanVerify] = useState(false);
  const [bankDetailsVerify, setBankDetailsVerify] = useState(false)
  const [showCampaignRedeem, setShowCampaignRedeem] = useState(false);

  const fetchConfigToRedeemDetail = async () => {
    const [error, result] = await asyncWrap(axios.get("config"));
    if (error) {
      console.log(error);
    } else {
      const rationofpoint = result.data.data.filter(
        item => item.FlagName === "RatioOfPoints"
      )[0].Value;
      setRatioOfPoints(rationofpoint);
    }
  };

  const getUserDetails = async () => {
    //eslint-disable-next-line
    const [error, result] = await asyncWrap(axios.get("/userDetails"));
    if (error?.response?.status === 401) {
      Swal.fire("Please Logout and login again");
      return;
    }
    if (!result) {
      console.log(error.response);
      return;
    }
    const user_Details = result.data.data;

    if (user_Details?.roleName === "Retailer") {
      if (!user_Details?.IsPanVerified || !user_Details?.PancardNumber) {
        const { value: PancardValue } = await Swal.fire({
          title: "Enter your Pancard",
          input: "text",
          inputLabel: "Your Pan Card",
          showCancelButton: false,
          inputValue: user_Details?.PancardNumber,
          allowOutsideClick: false,
          allowEscapeKey: false,
          inputValidator: value => {
            if (!value) {
              return "You need to write something!";
            }
            let pancardpattern = new RegExp(/[A-Z]{5}[0-9]{4}[A-Z]{1}/);
            if (!pancardpattern.test(value)) {
              return "Please enter valid pan number";
            }
          },
        });

        if (PancardValue) {
          let pancardpattern = new RegExp(/[A-Z]{5}[0-9]{4}[A-Z]{1}/);
          if (!pancardpattern.test(PancardValue)) {
            Swal.fire("Please enter valid pan number");
            return;
          }

          const [panError, panResult] = await asyncWrap(
            axios.post("/verifyKyc/verify-pan", {
              panNumber: PancardValue,
            })
          );

          if (!panResult) {
            Swal.fire(panError.response.data.data.message).then(() => {
              window.location.reload();
            });
            return;
          }
          if (!panResult.data.data.valid) {
            Swal.fire(panError.response.data.data.message).then(() => {
              window.location.reload();
            });
            return;
          } else {
            const [error, result] = await asyncWrap(
              axios.post(
                "/editProfile",
                user_Details?.PancardNumber !== PancardValue
                  ? {
                      userType: user?.profile?.roleName || user.roleName,
                      kycTypeId: "Pan Card",
                      userId: user?.profile?.userId || user.userId,
                      panNumber: PancardValue,
                      kycNumber: PancardValue,
                      ispanverify: 1,
                    }
                  : {
                      userType: user?.profile?.roleName || user.roleName,
                      userId: user?.profile?.userId || user.userId,
                      ispanverify: 1,
                    }
              )
            );
            if (!result) {
              Swal.fire(error.response.data.data.message);
              history.replace("/redeem");
            } else if (result.data?.success) {
              Swal.fire(result.data?.message);
              history.replace("/redeem");
            } else {
              Swal.fire(result?.data?.message).then(() => {
                window.location.reload();
              });
            }
          }
        }
      }
    }
    if (
      user_Details?.roleName === "Electrician" &&
      user_Details?.TotalEarnedPoints > 76000
    ) {
      if (!user_Details?.IsPanVerified || !user_Details?.PancardNumber) {
        const { value: PancardValue } = await Swal.fire({
          title: "Enter your Pancard",
          input: "text",
          inputLabel: "Your Pan Card",
          showCancelButton: false,
          inputValue: user_Details?.PancardNumber,
          allowOutsideClick: false,
          allowEscapeKey: false,
          inputValidator: value => {
            if (!value) {
              return "You need to write something!";
            }
            let pancardpattern = new RegExp(/[A-Z]{5}[0-9]{4}[A-Z]{1}/);
            if (!pancardpattern.test(value)) {
              return "Please enter valid pan number";
            }
          },
        });

        if (PancardValue) {
          let pancardpattern = new RegExp(/[A-Z]{5}[0-9]{4}[A-Z]{1}/);
          if (!pancardpattern.test(PancardValue)) {
            Swal.fire("Please enter valid pan number");
            return;
          }

          const [panError, panResult] = await asyncWrap(
            axios.post("/verifyKyc/verify-pan", {
              panNumber: PancardValue,
            })
          );

          if (!panResult) {
            Swal.fire(panError.response.data.data.message).then(() => {
              window.location.reload();
            });
            return;
          }
          if (!panResult.data.data.valid) {
            Swal.fire(panError.response.data.data.message).then(() => {
              window.location.reload();
            });
            return;
          } else {
            const [error, result] = await asyncWrap(
              axios.post(
                "/editProfile",
                user_Details?.PancardNumber !== PancardValue
                  ? {
                      userType: user?.profile?.roleName || user.roleName,
                      kycTypeId: "Pan Card",
                      userId: user?.profile?.userId || user.userId,
                      panNumber: PancardValue,
                      kycNumber: PancardValue,
                      ispanverify: 1,
                    }
                  : {
                      userType: user?.profile?.roleName || user.roleName,
                      userId: user?.profile?.userId || user.userId,
                      ispanverify: 1,
                    }
              )
            );
            if (!result) {
              Swal.fire(error.response.data.data.message);
              history.replace("/redeem");
            } else if (result.data?.success) {
              Swal.fire(result.data?.message);
              history.replace("/redeem");
            } else {
              Swal.fire(result?.data?.message).then(() => {
                window.location.reload();
              });
            }
          }
        }
      }
    }

    // if (
    //   (result.data.data.TotalEarnedPoints > 70000 &&
    //     !user_Details.IsPanVerified) ||
    //   !user_Details.PancardNumber
    // ) {
    //   const { value: PancardValue } = await Swal.fire({
    //     title: "Enter your Pancard",
    //     input: "text",
    //     inputLabel: "Your Pan Card",
    //     showCancelButton: false,
    //     inputValue: user_Details?.PancardNumber,
    //     allowOutsideClick: false,
    //     allowEscapeKey: false,
    //     inputValidator: value => {
    //       if (!value) {
    //         return "You need to write something!";
    //       }
    //       let pancardpattern = new RegExp(/[A-Z]{5}[0-9]{4}[A-Z]{1}/);
    //       if (!pancardpattern.test(value)) {
    //         return "Please enter valid pan number";
    //       }
    //     },
    //   });

    //   if (PancardValue) {
    //     let pancardpattern = new RegExp(/[A-Z]{5}[0-9]{4}[A-Z]{1}/);
    //     if (!pancardpattern.test(PancardValue)) {
    //       Swal.fire("Please enter valid pan number");
    //       return;
    //     }

    //     const [panError, panResult] = await asyncWrap(
    //       axios.post("/verifyKyc/verify-pan", {
    //         panNumber: PancardValue,
    //       })
    //     );

    //     if (!panResult) {
    //       Swal.fire(panError.response.data.data.message).then(() => {
    //         window.location.reload();
    //       });
    //       return;
    //     }
    //     if (!panResult.data.data.valid) {
    //       Swal.fire(panError.response.data.data.message).then(() => {
    //         window.location.reload();
    //       });
    //       return;
    //     } else {
    //       const [error, result] = await asyncWrap(
    //         axios.post(
    //           "/editProfile",
    //           user_Details?.PancardNumber !== PancardValue
    //             ? {
    //                 userType: user?.profile?.roleName || user.roleName,
    //                 kycTypeId: "Pan Card",
    //                 userId: user?.profile?.userId || user.userId,
    //                 panNumber: PancardValue,
    //                 kycNumber: PancardValue,
    //                 ispanverify: 1,
    //               }
    //             : {
    //                 userType: user?.profile?.roleName || user.roleName,
    //                 userId: user?.profile?.userId || user.userId,
    //                 ispanverify: 1,
    //               }
    //         )
    //       );
    //       if (!result) {
    //         Swal.fire(error.response.data.data.message);
    //         history.replace("/redeem");
    //       } else if (result.data?.success) {
    //         Swal.fire(result.data?.message);
    //         history.replace("/redeem");
    //       } else {
    //         Swal.fire(result?.data?.message).then(() => {
    //           window.location.reload();
    //         });
    //       }
    //     }
    //   }
    // }

    setUserApproved(result.data.data.callverification);
    setPanVerify(result.data.data.IsPanVerified);
    setPaytmNumber(result.data.data.paytmDetails.mobile);
    setUpiId(result.data.data.UpiId);
    setBankName(result.data.data.beneficiaryDetail.bankName);
    setAccount(result.data.data.beneficiaryDetail.accountNumber);
    setIfsc(result.data.data.beneficiaryDetail.ifscNumber);
    setName(result.data.data.beneficiaryDetail.name);
    setInstagramID(result?.data?.data?.InstagramId);
    setBankDetailsVerify(result.data?.data?.IsBankVerify)
  };

  const GetTotalPoints = async () => {
    const [error, result] = await asyncWrap(axios.get("/earnedPoints"));
    if (!result) {
      console.log(error);
      return;
    }
    setTotalRedeemPoint(result.data.data.totalPoints[0].point);
    setTotalNonRedeemPoint(result.data.data.totalPoints[1].point);
  };

  const getVouchers = async () => {
    const [error, result] = await asyncWrap(axios({ url: "/listCoupons" }));
    if (!result) {
      console.log(error);
      return;
    }
    setVouchers(result.data.data?.filter(item => item.CouponType === "Coupon"));
    setAllGiftDetail(
      result.data.data?.filter(item => item.CouponType === "Gift")
    );
  };

  useEffect(() => {
    getUserDetails();
  }, [showEditDetails]);

  useEffect(() => {
    GetTotalPoints();
    getUserDetails();
    getVouchers();
    fetchConfigToRedeemDetail();
    //eslint-disable-next-line
  }, []);

  const isValidPoint = () => {
    if (+point > 0 && point <= totalRedeemPoints && !isNaN(+point)) {
      return false;
    } else {
      return true;
    }
  };

  const redeemVoucher = async (point, productGuid) => {
    const [error, result] = await asyncWrap(
      axios.post("/addRedeem", {
        points: point,
        type: redeemType,
        productGUID: productGuid,
      })
    );
    if (!error) {
      Swal.fire(result.data.message);
      GetTotalPoints();
      return;
    }
    Swal.fire(result.data.message);
  };

  const redeemGift = async (point, productGuid) => {
    const [error, result] = await asyncWrap(
      axios.post("/addRedeem", {
        points: point,
        type: redeemType,
        productGUID: productGuid,
      })
    );
    if (!error) {
      Swal.fire(result.data.message);
      GetTotalPoints();
      return;
    }
    Swal.fire(result.data.message);
  };

  //eslint-disable-next-line
  const isvalidAccount = new RegExp("[0-9]{9,18}");

  //eslint-disable-next-line
  const isvalidIfsc = new RegExp("^[A-Z]{4}[0][A-Z0-9]{6}$");
  const isvalidPaytm = new RegExp(/^[6-9]\d{9}$/);
  const isvalidUpi = new RegExp("[a-zA-Z0-9.-_]{2,256}@[a-zA-Z]{2,64}");
  const isName = new RegExp(/^[a-zA-Z0-9\s]+$/);

  const EditDetails = async () => {
    setLoading(true);
    let data;
    if (redeemType === "Bank") {
      if (!isvalidAccount.test(account)) {
        Swal.fire("Please Enter valid Account Number!");
        return;
      }
      if (!isName.test(name)) {
        Swal.fire("Please Enter valid Account Holder Name!");
        return;
      }
      if (!isName.test(bankName)) {
        Swal.fire("Please Enter valid Bank Name!");
        return;
      }
      if (!isvalidIfsc.test(ifsc)) {
        Swal.fire("Please Enter valid Ifsc Code!");
        return;
      }
      const [error, result] = await asyncWrap(
        axios.post("verifyKyc/verify-bank", {
          bankAccount: account,
          ifsc: ifsc,
          name: name,
          phone: ""
        })
      );
      if (!result) {
        Swal.fire(error.response.data.data.message).then(() => {
          // setLoading(false) 
          window.location.reload()
        });
        // history.replace("/home");
      } else if (result.data?.success) {
        const [userError, userResult] = await asyncWrap(
          axios.post("/editProfile",  {
            beneficiaryDetail: {
              name: name,
              accountNumber: account,
              ifscNumber: ifsc,
              bankName: bankName
            },
            isBankVerify: true
          })
        );
        if (!userResult) {
          Swal.fire(error.response.data.data.message).then(() => {
            history.replace("/home");
          });
        } else if (userResult.data?.success) {
          Swal.fire(userResult.data.message).then(() => {
            // setLoading(false)
            window.location.reload();
            // history.replace("/home");
          });
        } else {
          Swal.fire(userResult?.data?.message).then(() => {
            // setLoading(false)
            window.location.reload();
          });
        }
      } else {
        Swal.fire(result?.data?.message).then(() => {
          window.location.reload();
          // setLoading(false)
        });
      }
      return;
    }
    if (redeemType === "Paytm") {
      if (!isvalidPaytm.test(paytmNumber)) {
        Swal.fire("Please Enter valid Paytm Number!");
        return;
      }
      data = {
        paytmDetails: {
          mobile: paytmNumber,
        },
      };
    }
    if (redeemType === "Upi") {
      if (!isvalidUpi.test(upiId)) {
        Swal.fire("Please input correct upi!");
        return;
      }
      data = {
        upiId: upiId,
      };
    }

    const [error, result] = await asyncWrap(axios.post("/editProfile", data));

    if (!result) {
      Swal.fire(error.response.data.message);
      return;
    }
    setLoading(false);
    Swal.fire(result.data.message).then(() => {
      history.push("/home");
    });
    // setShow(false);
  };

  const AddRedeem = async () => {
    if (!userApproved) {
      Swal.fire("Please complete your kyc verification!");
      return;
    }
    // if (!panVerify) {
    //   Swal.fire("Please complete your Pancard verification!");
    //   return;
    // }
    if (point > 16000) {
      Swal.fire("Please Redeem less then 16000!");
      return;
    }
    if (!isValidPoint()) {
      if (redeemType === "Transfer Points") {
        //eslint-disable-next-line
        const [error, result] = await asyncWrap(
          axios.post("/transferPoints", {
            mobileNumber: mobile,
            point: point,
          })
        );
        console.log(error);
        if (!result) {
          setShowFailedModal(true);
          return;
          // Swal.fire(error.response.data.message);
        } else if (result.data.code === 1) {
          setShowSuccessModal(true);
        } else {
          Swal.fire(result.data.message);
        }
        // Swal.fire(result.data.message);
      } else {
        //eslint-disable-next-line
        const [error, result] = await asyncWrap(
          axios.post("/addRedeem", {
            points: point,
            type: redeemType,
          })
        );
        if (!result) {
          setShowFailedModal(true);
          // Swal.fire(error.response.data.message);
        }
        console.log(result.data.data);
        if (result.data.code === 1) {
          setShowSuccessModal(true);
        } else if (result.data.code === 3) {
          Swal.fire(
            "Your previous payment request is processing, please try again when its completed. Thank you~"
          );
        } else {
          Swal.fire(result.data?.message);
          // Swal.fire(result.data.message);
        }
      }
      GetTotalPoints();
    } else {
      Swal.fire("Please enter valid points to redeem!");
    }
  };

  return (
    <>
      <PaytmModal
        show={showPaytmModal}
        setShow={setShowPaytmModal}
        paytmNumber={paytmNumber}
        setPaytmNumber={setPaytmNumber}
        point={point}
        setPoint={setPoint}
        addRedeemClick={AddRedeem}
        editProfile={EditDetails}
      />
      <BankModal
        show={showBankModal}
        setShow={setShowBankModal}
        account={account}
        setAccount={setAccount}
        name={name}
        setName={setName}
        bankName={bankName}
        setBankName={setBankName}
        ifsc={ifsc}
        setIfsc={setIfsc}
        point={point}
        setPoint={setPoint}
        addRedeemClick={AddRedeem}
        editProfile={EditDetails}
        bankDetailsVerify={bankDetailsVerify}
      />
      <UpiModal
        show={showUpiModal}
        setShow={setShowUpiModal}
        upiId={upiId}
        setUpiId={setUpiId}
        point={point}
        setPoint={setPoint}
        addRedeemClick={AddRedeem}
        editProfile={EditDetails}
      />
      <TransferPointsModal
        show={transferPointModal}
        setShow={setTransferPointsModal}
        mobile={mobile}
        setMobile={setMobile}
        point={point}
        setPoint={setPoint}
        addRedeemClick={AddRedeem}
      />
      <VoucherModal
        show={voucherModal}
        setShow={setVoucherModal}
        totalRedeemPoints={totalRedeemPoints}
        voucherData={vouchers}
        redeemVoucher={redeemVoucher}
      />
      <GiftModel
        totalNonRedeemPoints={totalNonRedeemPoints}
        show={showGiftModel}
        setShow={setShowGiftModel}
        allGiftDetail={allGiftDetail}
        redeemGift={redeemGift}
      />
      <SuccessModal
        show={showSuccessModal}
        setShow={setShowSuccessModal}
        point={point}
      />
      <FailedModal
        show={showFailedModal}
        setShow={setShowFailedModal}
        point={point}
      />
      <CampaignRedeem
        show={showCampaignRedeem}
        setShow={setShowCampaignRedeem}
        point={point}
        setPoint={setPoint}
        addRedeemClick={AddRedeem}
      />
      <MissingKyc show={showMissingKyc} setShow={setShowMissingKyc} />
      <Spin spinning={loading}>
        <div
          className="mainpage"
          style={{ background: "#FFF", fontSize: "15px" }}
        >
          <EditRedeemDetails
            show={showEditDetails}
            setShow={setShowEditDetails}
            redeemType={redeemType}
          />
          <p
            className="fw-bold"
            style={{ fontSize: "1.5rem", marginBottom: 0, color: "#434343" }}
            onClick={() => {
              setShowMissingKyc(true);
            }}
          >
            {t("redeem")}
          </p>

          <div
            style={{ color: "#7B7B7B" }}
            className="d-flex flex-column justify-content-center"
          >
            {/* {!(
              (user?.profile?.roleName || user?.roleName) === "Retailer" &&
              parseInt(instagramID) > 0
            ) && (
              <div className="d-flex flex-column">
                <div className="d-flex flex-row align-items-center justify-content-center mt-3">
                  <img
                    style={{
                      marginRight: "10px",
                      width: "35px",
                      height: "35px",
                    }}
                    src="/assets/images/Redeem/trophy.png"
                    alt="trophyimage"
                  />

                  <div
                    style={{ color: "#3A69DD", fontSize: "35px" }}
                    className="fw-bold"
                  >
                    {totalRedeemPoints}
                  </div>

                  <img
                    style={{
                      marginLeft: "10px",
                      width: "35px",
                      height: "35px",
                    }}
                    src="/assets/images/Redeem/trophy.png"
                    alt="tropyimage"
                  />
                </div>
                <div
                  style={{ color: "#7B7B7B", fontSize: "18px" }}
                  className="fw-bold mt-1"
                >
                  {t("rewards_points")}
                </div>
              </div>
            )} */}
            {/* {(user?.profile?.roleName || user?.roleName) !== "Retailer" && (
              <div className="d-flex flex-column">
                <div className="d-flex flex-row align-items-center justify-content-center mt-3">
                  <img
                    style={{
                      marginRight: "10px",
                      width: "35px",
                      height: "35px",
                    }}
                    src="/assets/images/Redeem/trophy.png"
                    alt="trophyimage"
                  />

                  <div
                    style={{ color: "#3A69DD", fontSize: "35px" }}
                    className="fw-bold"
                  >
                    {totalNonRedeemPoints}
                  </div>

                  <img
                    style={{
                      marginLeft: "10px",
                      width: "35px",
                      height: "35px",
                    }}
                    src="/assets/images/Redeem/trophy.png"
                    alt="tropyimage"
                  />
                </div>
                <div
                  style={{ color: "#7B7B7B", fontSize: "18px" }}
                  className="fw-bold mt-1"
                >
                  {t("gift_point")}
                </div>
              </div>
            )} */}

             {(user?.profile?.roleName || user?.roleName) === "Electrician" && (
              <div className="d-flex flex-column">
                <div className="d-flex flex-row align-items-center justify-content-center mt-3">
                  <img
                    style={{
                      marginRight: "10px",
                      width: "35px",
                      height: "35px",
                    }}
                    src="/assets/images/Redeem/trophy.png"
                    alt="trophyimage"
                  />

                  <div
                    style={{ color: "#3A69DD", fontSize: "35px" }}
                    className="fw-bold"
                  >
                    {totalRedeemPoints}
                  </div>

                  <img
                    style={{
                      marginLeft: "10px",
                      width: "35px",
                      height: "35px",
                    }}
                    src="/assets/images/Redeem/trophy.png"
                    alt="tropyimage"
                  />
                </div>
                <div
                  style={{ color: "#7B7B7B", fontSize: "18px" }}
                  className="fw-bold mt-1"
                >
                  Current Points Balance
                  {/* {t("gift_point")} */}
                </div>
              </div>
            )} 
            <div style={{ width: "90%" }} className="mt-3 mx-auto">
              {t("you_can_transfer_your_money_to_any_of_following_method")}
            </div>
            <div className="mt-3">
              <div
                style={{ color: "#7B7B7B", fontSize: "18px" }}
                className="fw-bold mt-1"
              >
                {t("transfer_rate")}
              </div>
              <div
                style={{ color: "#7B7B7B", fontSize: "18px" }}
                className="fw-bold mt-2"
              >
                {`🏆 ${ratioOfPoints} = ₹ 1`}
              </div>
            </div>
          </div>

          <div
            style={{ color: "#434343", fontSize: "16px" }}
            className="d-flex fw-bold flex-column mt-4"
          >
            {webAppConfigIsVisible(
              (user?.profile?.roleName || user?.roleName) === "Retailer"
                ? "Retailer Paytm"
                : "Electrician Paytm",
              webAppConfigData
            ) && (
              <div
                onClick={() => {
                  setRedeemType("Paytm");
                  setPoint("");
                  eventLog("Clicked Paytm", "click", "00:00:01");
                  setShowPaytmModal(true);
                }}
                className="d-flex flex-row justify-content-between align-items-center shadow p-3 mx-3 my-2 rounded-2"
              >
                <div>{t("transfer_to_paytm_wallet")}</div>
                <PaytmIcon />
              </div>
            )}

            {webAppConfigIsVisible(
              (user?.profile?.roleName || user?.roleName) === "Retailer"
                ? "Retailer UPI"
                : "Electrician UPI",
              webAppConfigData
            ) && (
              <div
                onClick={() => {
                  setRedeemType("Upi");
                  setPoint("");
                  eventLog("Clicked Upi", "click", "00:00:01");
                  setShowUpiModal(true);
                }}
                className="d-flex flex-row justify-content-between align-items-center shadow p-3 mx-3 my-2 rounded-2"
              >
                <div>{t("transfer_to_any_upi")}</div>
                <BhimUpiIcon />
              </div>
            )}
            {webAppConfigIsVisible(
              (user?.profile?.roleName || user?.roleName) === "Retailer"
                ? "Retailer Bank"
                : "Electrician Bank",
              webAppConfigData
            ) && (
              <div
                onClick={() => {
                  setRedeemType("Bank");
                  setPoint("");
                  eventLog("Clicked Bank", "click", "00:00:01");
                  setShowBankModal(true);
                }}
                className="d-flex flex-row justify-content-between align-items-center shadow p-3 mx-3 my-2 rounded-2"
              >
                <div>{t("transfer_to_bank")}</div>
                <BankIcon />
              </div>
            )}

            {webAppConfigIsVisible(
                (user?.profile?.roleName || user?.roleName) === "Retailer"
                  ? "Retailer Voucher"
                  : "Electrician Voucher",
                webAppConfigData
              ) && (
                <div
                  onClick={() => {
                    setRedeemType("Coupon");
                    setPoint("");
                    eventLog("Clicked Coupon", "click", "00:00:01");
                    setVoucherModal(true);
                  }}
                  className="d-flex flex-row justify-content-between align-items-center shadow p-3 mx-3 my-2 rounded-2"
                >
                  <div>{t("vouchers")}</div>
                  <img
                    width="30px"
                    alt="voucher.png"
                    src="/assets/images/voucher.png"
                  />
                </div>
              )}

            {webAppConfigIsVisible(
              (user?.profile?.roleName || user?.roleName) === "Retailer"
                ? "Retailer Gift"
                : "Electrician Gift",
              webAppConfigData
            ) && (
              <div
                onClick={() => {
                  setRedeemType("Gift");
                  setPoint("");
                  eventLog("Clicked Coupon", "click", "00:00:01");
                  setShowGiftModel(true);
                }}
                className="d-flex flex-row justify-content-between align-items-center shadow p-3 mx-3 my-2 rounded-2"
              >
                <div>Gift</div>
                <img
                  width="30px"
                  alt="voucher.png"
                  src="/assets/images/voucher.png"
                />
              </div>
              )}
            {(user?.profile?.roleName || user?.roleName) === "Retailer" && (
              <div
                onClick={() => {
                  setRedeemType("Bank");
                  setPoint("");
                  eventLog("Clicked Coupon", "click", "00:00:01");
                  setShowCampaignRedeem(true);
                }}
                className="d-flex flex-row justify-content-between align-items-center shadow p-3 mx-3 my-2 rounded-2"
              >
                <div>Campaign Redeem</div>
                <img
                  width="30px"
                  alt="voucher.png"
                  src="/assets/images/voucher.png"
                />
              </div>)}

          </div>

          <div
            onClick={() => {
              history.push("/points-info");
            }}
            style={{ color: "#3A69DD" }}
            className="fw-bold fs-5 mt-4"
          >
            {t("show_history")}
          </div>
        </div>
      </Spin>
    </>
  );
};

export default RedeemPage;
