import { Modal } from "react-bootstrap";
import { useState } from "react";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { t } from "i18next";

const BankModal = ({
  show,
  setShow,
  account,
  setAccount,
  name,
  setName,
  bankName,
  setBankName,
  ifsc,
  setIfsc,
  point,
  setPoint,
  editProfile,
  addRedeemClick,
  bankDetailsVerify
}) => {
  const [disableEditDetail, setDisableEditDetail] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false)
  return (
    <Modal fullscreen={true} show={show}>
      <Modal.Body className="mt-4">
        <Form className="w-100">
          <FormGroup floating className="p-0">
            <Input
              style={{ border: "1px solid #B0B0B0", width: "100%" }}
              required
              id="account_number"
              value={account}
              disabled={!disableEditDetail || bankDetailsVerify}
              onChange={(e) => setAccount(e.target.value)}
              placeholder="Enter Your Account Number"
              type="number"
            />
            <Label htmlFor="account_number">{t("ac_number")}</Label>
          </FormGroup>
          <FormGroup floating className="p-0">
            <Input
              style={{ border: "1px solid #B0B0B0", width: "100%" }}
              required
              id="ifsc_number"
              value={ifsc}
              disabled={!disableEditDetail || bankDetailsVerify}
              onChange={(e) => setIfsc(e.target.value)}
              placeholder="Enter Your IFSC Number"
              type="text"
            />
            <Label htmlFor="ifsc_number">{t("ifsc_code")}</Label>
          </FormGroup>
          <FormGroup floating className="p-0">
            <Input
              style={{ border: "1px solid #B0B0B0", width: "100%" }}
              required
              id="account_name"
              value={name}
              disabled={!disableEditDetail || bankDetailsVerify}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter Your Account Holder Name"
              type="text"
            />
            <Label htmlFor="account_name">{t("name")}</Label>
          </FormGroup>
          <FormGroup floating className="p-0">
            <Input
              style={{ border: "1px solid #B0B0B0", width: "100%" }}
              required
              id="bank_name"
              value={bankName}
              disabled={!disableEditDetail || bankDetailsVerify}
              onChange={(e) => setBankName(e.target.value)}
              placeholder="Enter your bank name"
              type="text"
            />
            <Label htmlFor="bank_name">{t("bank_name")}</Label>
            {!bankDetailsVerify  && (<div
              onClick={() => setDisableEditDetail(!disableEditDetail)}
              style={{ color: "#3A69DD" }}
              className="text-end mt-0"
            >
              {t("edit_details")}
            </div>)}
          </FormGroup>

          {disableEditDetail ? (
            <div className="d-flex justify-content-center">
              <button
                disabled={disableBtn}
                onClick={() => {
                  setDisableBtn(true)
                  editProfile()
                }}
                style={{ background: "#3A69DD", color: "white" }}
                className="fw-bold fs-5 shadow align-self-center rounded-5 px-4 py-2 border-0 "
              >
                {t("submit")}
              </button>
            </div>
          ) : null}

          <FormGroup floating className="p-0 mt-3">
            <Input
              style={{ border: "1px solid #B0B0B0", width: "100%" }}
              required
              onChange={(e) => setPoint(e.target.value)}
              value={point}
              placeholder="Point"
              id="point"
              type="number"
            />
            <Label htmlFor="point">{t("points")}</Label>
          </FormGroup>
          <div className="d-flex flex-column justify-content-center">
            <button
              onClick={(e) => {
                e.preventDefault();
                addRedeemClick();
              }}
              style={{ background: "#3A69DD", color: "white" }}
              className="fw-bold fs-5 shadow align-self-center rounded-5 px-4 py-2 border-0 "
            >
              {t("redeem")}
            </button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default BankModal;
